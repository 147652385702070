import * as React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { IS_PROD, urls } from '../config';

export const SEO = ({
  title = null,
  description = null,
  image = null,
  pathname = null,
  indexable = true,
  titleTemplate,
  breadcrumbElements
}: {
  title: string;
  description?: string;
  image?: string;
  pathname: string;
  article?: boolean;
  indexable?: boolean;
  titleTemplate?: string;
  breadcrumbElements?: {
    title: string;
    url: string;
  }[];
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate: defaultTitleTemplate,
          defaultImage,
          defaultImageWidth,
          defaultImageHeight,
          twitterUsername
        }
      }
    }) => {
      const template = titleTemplate || defaultTitleTemplate;

      const seo = {
        description: description,
        image: `${urls.website}${image || defaultImage}`,
        url: `${urls.website}${pathname || '/'}`,
        title: template.replace('%s', title || defaultTitle)
      };

      const ldJSON = JSON.stringify([
        {
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          publisher: {
            '@type': 'Organization',
            name: 'Churni',
            logo: {
              '@type': 'ImageObject',
              url: `${urls.website}${defaultImage}`,
              width: defaultImageWidth,
              height: defaultImageHeight
            }
          },
          url: seo.url,
          description,
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': urls.website
          }
        },
        ...(breadcrumbElements && breadcrumbElements.length > 1
          ? [
              {
                '@context': 'https://schema.org',
                '@type': 'BreadcrumbList',
                itemListElement: breadcrumbElements.map((element, i) => ({
                  '@type': 'ListItem',
                  position: i + 1,
                  name: element.title,
                  item: {
                    '@type': 'Thing',
                    '@id': `${urls.website}${element.url}`
                  }
                }))
              }
            ]
          : [])
      ]);

      return (
        <>
          <Helmet>
            <meta
              name="robots"
              content={IS_PROD && indexable ? 'index' : 'noindex'}
            />
            <html lang={'en'} />
            <link
              rel="canonical"
              href={`https://www.churni.io/${pathname || ''}`}
            />

            {/* title */}
            <title>{seo.title}</title>
            <meta property="og:title" content={seo.title} />
            <meta name="twitter:title" content={seo.title} />

            {/* description */}
            {description && (
              <meta name="description" content={seo.description} />
            )}
            {description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {description && (
              <meta property="og:description" content={seo.description} />
            )}

            {/* image */}
            <meta name="image" content={seo.image} />
            <meta name="twitter:image" content={seo.image} />
            <meta property="og:image" content={seo.image} />
            {!image && (
              <meta property="og:image:width" content={defaultImageWidth} />
            )}
            {!image && (
              <meta property="og:image:width" content={defaultImageHeight} />
            )}

            {/* other stuff */}

            <meta name="og:site_name" content={defaultTitle} />
            <meta property="og:url" content={seo.url} />
            <meta property="og:type" content="website" />

            {/* twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {/* microdata */}
            <script type="application/ld+json">{`${ldJSON}`}</script>
          </Helmet>
        </>
      );
    }}
  />
);

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultImageWidth: imageWidth
        defaultImageHeight: imageHeight
        twitterUsername
      }
    }
  }
`;
